<template>
  <div>
    <v-dialog v-model="show" max-width="600" persistent>
      <v-card class="px-8 py-8">
        <v-row>
          <v-col class="d-flex justify-space-between">
            <span style="font-weight: bold"> Tambah Acara </span>
            <span style="cursor: pointer" @click="close"> X </span>
          </v-col>
        </v-row>

        <v-row class="mt-8">
          <v-col class="pt-4 txt16-gray50" cols="12" sm="4">
            BPC / BPD <span class="symbol-required">*</span>
          </v-col>
          <v-col cols="12" sm="8">
            <v-combobox
              v-model="vEntity"
              :items="entityMain"
              color="#36AC87"
              item-text="name"
              item-value="ent_hash"
              placeholder="Pilih Entitas"
              outlined
              dense
            />
          </v-col>
        </v-row>

        <v-row v-for="v in subEntityLength" :key="'entity-' + v" class="mt-n6">
          <v-col class="pt-4 txt16-gray50" cols="12" sm="4">
            <span v-if="v === 1"> Kolaborasi </span>
          </v-col>
          <v-col cols="12" sm="8">
            <v-combobox
              :id="'sub-entity-' + v"
              v-model="vSubEntity[v - 1]"
              :items="entity"
              color="#36AC87"
              item-text="name"
              item-value="ent_hash"
              placeholder="Pilih Sub Entitas"
              outlined
              dense
            />
          </v-col>
        </v-row>

        <v-row class="mt-n6 mb-4">
          <v-col class="pt-4 txt16-gray50" cols="12" sm="4" />
          <v-col cols="12" sm="8">
            <v-btn
              class="text-capitalize"
              color="#36AC87"
              block
              outlined
              @click="
                vSubEntity.push('');
                subEntityLength += 1;
              "
            >
              Tambah Kolom
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pt-4 txt16-gray50" cols="12" sm="4">
            Bidang <span class="symbol-required">*</span>
          </v-col>
          <v-col cols="12" sm="8">
            <v-select
              v-model="vBidang"
              :items="bidang"
              placeholder="Pilih Bidang"
              item-text="dept_name"
              item-value="dept_hash"
              color="#36AC87"
              dense
              outlined
            />
          </v-col>
        </v-row>

        <v-row class="mt-2">
          <v-col class="pt-4 txt16-gray50" cols="12" sm="4">
            Program Kerja
          </v-col>
          <v-col cols="12" sm="8">
            <v-select
              v-model="vProgramKerja"
              :items="programKerja"
              placeholder="Tidak terkait ke program kerja"
              item-text="title"
              item-value="wp_hash"
              color="#36AC87"
              dense
              outlined
              persistent-hint
            />
            <div class="mt-n6 txt12-gray50">
              Jika acara yang anda tambahkan tidak ada kaitan dengan program
              kerja maka tetap pilih "Tidak terkait ke program kerja"
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pt-4 txt16-gray50" cols="12" sm="4">
            Judul <span class="symbol-required">*</span>
          </v-col>
          <v-col cols="12" sm="8">
            <v-text-field
              v-model="judul"
              type="text"
              outlined
              autocomplete="off"
              dense
              color="#36AC87"
              placeholder="Judul Acara"
            />
          </v-col>
        </v-row>

        <v-row class="mt-n6">
          <v-col class="pt-4 txt16-gray50" cols="12" sm="4">
            Waktu <span class="symbol-required">*</span>
          </v-col>
          <v-col cols="12" sm="4">
            <div class="txt16-gray50">Tanggal Mulai</div>

            <div data-app class="mt-2">
              <v-dialog
                ref="modalTanggalMulai"
                v-model="modalTanggalMulai"
                :return-value.sync="dateStart"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateStart"
                    placeholder="Pilih Tanggal"
                    readonly
                    color="#36AC87"
                    v-bind="attrs"
                    dense
                    outlined
                    v-on="on"
                  />
                </template>
                <v-date-picker v-model="dateStart" scrollable color="#36AC87">
                  <v-spacer />
                  <v-btn
                    text
                    color="primary"
                    @click="modalTanggalMulai = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.modalTanggalMulai.save(dateStart)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </div>

            <div class="txt16-gray50">Jam Mulai</div>

            <div>
              <v-dialog
                ref="timeStart"
                v-model="modal2"
                :return-value.sync="timeStart"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="timeStart"
                    placeholder="Pilih Jam"
                    readonly
                    v-bind="attrs"
                    outlined
                    dense
                    class="mt-1"
                    v-on="on"
                  />
                </template>
                <v-time-picker v-if="modal2" v-model="timeStart" full-width>
                  <v-spacer />
                  <v-btn text color="primary" @click="modal2 = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.timeStart.save(timeStart)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </div>
          </v-col>

          <v-col cols="12" sm="4">
            <div class="txt16-gray50">Tanggal Akhir</div>

            <div data-app class="mt-2">
              <v-dialog
                ref="modalTanggalAkhir"
                v-model="modalTanggalAkhir"
                :return-value.sync="dateEnd"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateEnd"
                    placeholder="Pilih Tanggal"
                    readonly
                    color="#36AC87"
                    v-bind="attrs"
                    dense
                    outlined
                    v-on="on"
                  />
                </template>
                <v-date-picker v-model="dateEnd" scrollable color="#36AC87">
                  <v-spacer />
                  <v-btn
                    text
                    color="primary"
                    @click="modalTanggalAkhir = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.modalTanggalAkhir.save(dateEnd)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </div>

            <div class="txt16-gray50">Jam Selesai</div>

            <div>
              <v-dialog
                ref="timeEnd"
                v-model="modal3"
                :return-value.sync="timeEnd"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="timeEnd"
                    placeholder="Pilih Jam"
                    readonly
                    v-bind="attrs"
                    outlined
                    dense
                    class="mt-1"
                    v-on="on"
                  />
                </template>
                <v-time-picker v-if="modal3" v-model="timeEnd" full-width>
                  <v-spacer />
                  <v-btn text color="primary" @click="modal3 = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.timeEnd.save(timeEnd)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <v-row class="mt-n4">
          <v-col class="txt16-gray50" cols="12" sm="4">
            Lokasi <span class="symbol-required">*</span>
          </v-col>

          <v-col class="d-flex" cols="12" sm="8" style="flex-direction: column">
            <v-text-field
              v-model="lokasi"
              type="text"
              outlined
              autocomplete="off"
              dense
              color="#36AC87"
              placeholder="Nama Gedung/ Tempat"
            />

            <div class="mt-n2">
              <v-textarea
                v-model="alamatLengkap"
                rows="4"
                no-resize
                outlined
                color="#36AC87"
                placeholder="Alamat Lengkap Gedung/ Tempat acara"
              />
            </div>
          </v-col>
        </v-row>

        <v-row class="mt-n6">
          <v-col class="txt16-gray50" cols="12" sm="4"> Keterangan </v-col>

          <v-col class="d-flex" cols="12" sm="8" style="flex-direction: column">
            <div class="mt-2">
              <v-textarea
                v-model="keterangan"
                rows="4"
                no-resize
                outlined
                color="#36AC87"
                placeholder="Masukkan Keterangan"
              />
            </div>
          </v-col>
        </v-row>

        <v-row class="mt-n6">
          <v-col class="txt16-gray50" cols="12" sm="4"> Lampiran </v-col>

          <v-col class="d-flex" cols="12" sm="8" style="flex-direction: column">
            <upload-button
              class="px-0"
              :is-loading="isLoading"
              :upload-files="uploadedFiles"
              multiple
              @uploadButtonClick="uploadFile"
            />
            <v-file-input
              id="upload-file"
              accept="image/*"
              style="display: none"
              multiple
              @change="upload"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-right">
            <v-btn
              color="#2E976C"
              class="mx-1 text-capitalize"
              style="font-weight: bold; color: white"
              @click="modal = true"
            >
              Simpan
            </v-btn>
            <v-btn
              color="#CCCCCC"
              class="mx-1 text-capitalize"
              style="font-weight: bold; color: white"
              @click="close"
            >
              Batal
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <modal-konfirmasi :show="modal" @submit="confirm" />
  </div>
</template>

<script>
import UploadButton from "../komponen/UploadButton.vue";
import ModalKonfirmasi from "../komponen/modal/ModalKonfirmasi.vue";
import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.headers.post["x-api-key"] = localStorage.getItem("token");

export default {
  components: {
    UploadButton,
    ModalKonfirmasi,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    dataModal: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    modal: false,
    modal2: false,
    modal3: false,
    judul: "",
    keterangan: "",
    alamatLengkap: "",
    modalTanggalMulai: false,
    modalTanggalAkhir: false,
    dateStart: "",
    lokasi: "",
    dateEnd: "",
    timeStart: "",
    timeEnd: "",
    programKerja: [],
    vProgramKerja: "",
    bidang: [],
    vBidang: "",
    isLoading: false,
    uploadedFiles: [],
    entityMain: [],
    entity: [],
    vEntity: null,
    subEntityLength: 2,
    subEntity: "",
    vSubEntity: [],
  }),

  computed: {},

  watch: {},

  created() {
    this.getProgramKerja();
    this.getCompanySector();
    this.getEntity();
    this.getEntityMain();
    for (let i = 0; i < this.subEntityLength; i++) {
      this.vSubEntity[i] = "";
    }
  },

  methods: {
    close() {
      this.$emit("close");
    },

    previewURL(p) {
      // console.log(p)
      const url = [];
      url.push(p.url);
      this.$viewerApi({ images: url });
    },

    confirm(p) {
      // console.log(p)
      if (p === 0) {
        this.modal = false;
      } else {
        this.modal = false;
        this.submit();
      }
    },

    uploadFile() {
      document.getElementById("upload-file").click();
    },

    getProgramKerja() {
      const requestBody = {};

      axios
        .post("/v1/general/get-working-plan", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            this.programKerja = res.data.data.working_plan.list;
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0][0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },

    getCompanySector() {
      axios.post("/v1/general/get-department").then((res) => {
        if (res.data.status === 200) {
          this.bidang = res.data.data.department.list;
        }
      });
    },

    getEntityMain() {
      axios.post("/v1/general/get-entity", { restrict: true }).then((res) => {
        if (res.data.status === 200) {
          this.entityMain = res.data.data.entity.list;
        }
      });
    },

    getEntity() {
      axios.post("/v1/general/get-entity").then((res) => {
        if (res.data.status === 200) {
          this.entity = res.data.data.entity.list;
        }
      });
    },

    async upload(e) {
      this.isLoading = true;
      const length = e.length;
      for (let i = 0; i < length; i++) {
        const res = await this.postUpload(e[i]);
        if (res === "NOK") {
          this.$toast.error("Upload file(s) failed");
          this.uploadedFiles = [];
          return;
        }
      }
      this.isLoading = false;
    },

    postUpload(e) {
      // console.log(e)
      return new Promise((resolve) => {
        const baseCDNurl = process.env.VUE_APP_CDN_BASE_URL;
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const requestBody = new FormData();
        requestBody.append("file", e);
        requestBody.append("dest", "member_photo");

        axios
          .post(baseCDNurl + "/v1/upload", requestBody, config)
          .then((res) => {
            if (res.data.status === 200) {
              this.uploadedFiles.push(res.data.data);
              resolve("OK");
            }
          })
          .catch((e) => {
            resolve("NOK");
            if (typeof e.response.data.error === "object") {
              this.$toast.error(Object.values(e.response.data.error)[0][0]);
            } else {
              this.$toast.error(e.response.data.error);
            }
          });
      });
    },

    submit() {
      const files = [];
      if (this.uploadedFiles.length > 0) {
        this.uploadedFiles.map((v) => {
          files.push(v.tmp_file_hash);
        });
      }

      const subEntity = [];
      this.vSubEntity.map((v) => {
        if (v !== "") subEntity.push(v.ent_hash);
      });

      const requestBody = {
        wp_hash: this.vProgramKerja,
        dept_hash: this.vBidang,
        name: this.judul,
        date_start: this.dateStart,
        time_start: this.timeStart,
        date_end: this.dateEnd,
        time_end: this.timeEnd,
        place: this.lokasi,
        address: this.alamatLengkap,
        desc: this.keterangan,
        list_file: files,
        ent_hash: this.vEntity.ent_hash,
        list_sub_ent: subEntity,
      };

      axios
        .post("/v1/admin/event/create", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.success(res.data.message);
            this.close();
            this.getProgramKerja();
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0][0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.txt12-gray50 {
  @extend .p-2;
  color: $gray-50;
}

.txt16-gray50 {
  @extend .p-1;
  color: $gray-50;
}

.txt16-gray50-bold {
  @extend .p-1;
  color: $gray-50;
  font-weight: bold;
}

.txt26-black {
  @extend .h-4;
}

.symbol-required {
  color: $red-1;
}

.action-container {
  background: #f2f2f2;
}

.action-container:hover {
  background: #2b7dec;
}

.action-container:hover > .action-icon {
  color: #f2f2f2;
}

.action-icon {
  font-size: 16px;
  color: #2b7dec;
}

tbody > tr:hover {
  background-color: transparent !important;
}
</style>
