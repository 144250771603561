<template>
  <div>
    <v-dialog
      v-model="show"
      max-width="600"
      persistent
    >
      <v-card class="px-8 pt-8 pb-12">
        <v-row>
          <v-col class="d-flex justify-space-between">
            <span style="font-weight: bold">
              Detail Acara
            </span>
            <span
              style="cursor: pointer"
              @click="close"
            >
              X
            </span>
          </v-col>
        </v-row>

        <v-row class="mt-4">
          <v-col
            class="txt16-gray50"
            cols="12"
            sm="4"
          >
            Entitas
          </v-col>
          <v-col
            cols="12"
            sm="8"
          >
            {{ dataModal.entity_main.name || '-' }}
          </v-col>
        </v-row>

        <v-row
          v-for="(v, i) in dataModal.sub_entity"
          :key="'entity-' + i"
          class=""
        >
          <v-col
            class="txt16-gray50"
            cols="12"
            sm="4"
          >
            <span v-if="i === 0">
              Sub Entitas
            </span>
          </v-col>
          <v-col
            class="d-flex flex-column"
            cols="12"
            sm="8"
          >
            <div>
              {{ v.name }}
            </div>
          </v-col>
        </v-row>

        <v-row class="">
          <v-col
            class="txt16-gray50"
            cols="12"
            sm="4"
          >
            Program Kerja
          </v-col>
          <v-col
            cols="12"
            sm="8"
          >
            {{ dataModal.title || '-' }}
          </v-col>
        </v-row>

        <v-row class="">
          <v-col
            class="txt16-gray50"
            cols="12"
            sm="4"
          >
            Bidang
          </v-col>
          <v-col
            cols="12"
            sm="8"
          >
            {{ dataModal.sector || '-' }}
          </v-col>
        </v-row>

        <v-row class="">
          <v-col
            class="txt16-gray50"
            cols="12"
            sm="4"
          >
            Judul
          </v-col>
          <v-col
            cols="12"
            sm="8"
          >
            {{ dataModal.title || '-' }}
          </v-col>
        </v-row>

        <v-row class="">
          <v-col
            class="txt16-gray50"
            cols="12"
            sm="4"
          >
            Waktu
          </v-col>
          <v-col
            cols="12"
            sm="8"
          >
            {{ dataModal.date || '-' }}
          </v-col>
        </v-row>

        <v-row class="">
          <v-col
            class="txt16-gray50"
            cols="12"
            sm="4"
          >
            Jam
          </v-col>
          <v-col
            cols="12"
            sm="8"
          >
            {{ dataModal.time || '-' }}
          </v-col>
        </v-row>

        <v-row class="">
          <v-col
            class="txt16-gray50"
            cols="12"
            sm="4"
          >
            Lokasi
          </v-col>
          <v-col
            cols="12"
            sm="8"
          >
            {{ dataModal.place + ',' + dataModal.address || '-' }}
          </v-col>
        </v-row>

        <v-row class="">
          <v-col
            class="txt16-gray50"
            cols="12"
            sm="4"
          >
            Keterangan
          </v-col>
          <v-col
            cols="12"
            sm="8"
          >
            {{ dataModal.desc || '-' }}
          </v-col>
        </v-row>

        <v-row class="">
          <v-col
            class="txt16-gray50"
            cols="12"
            sm="4"
          >
            Lampiran
          </v-col>
          <v-col
            cols="12"
            sm="8"
          >
            <card-file
              v-for="(v, i) in dataModal.list_file"
              :key="'file-' + i"
              class="mb-2"
              :file-data="v"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import CardFile from '../komponen/card/CardFile.vue'
  export default {

    components: {
      CardFile,
    },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      dataModal: {
        type: Object,
        default: null,
      },
    },

    data: () => ({
      modal: false,
      modal2: false,
      modal3: false,
      judul: '',
      keterangan: '',
      alamatLengkap: '',
      modalTanggalMulai: false,
      modalTanggalAkhir: false,
      dateStart: '',
      lokasi: '',
      dateEnd: '',
      timeStart: '',
      timeEnd: '',
      programKerja: [],
      vProgramKerja: '',
      bidang: [],
      vBidang: '',
      isLoading: false,
      uploadedFiles: [],
      entity: [],
      vEntity: null,
      subEntityLength: 3,
      subEntity: '',
      vSubEntity: [],
    }),

    computed: {},

    watch: {},

    created () {
    },

    methods: {
      close () {
        this.$emit('close')
      },

      previewURL (p) {
        // console.log(p)
        const url = []
        url.push(p.url)
        this.$viewerApi({ images: url })
      },
    },
  }
</script>

<style lang="scss" scoped>
.txt12-gray50 {
  @extend .p-2;
  color: $gray-50;
}

.txt16-gray50 {
  @extend .p-1;
  color: $gray-50;
}

.txt16-gray50-bold {
  @extend .p-1;
  color: $gray-50;
  font-weight: bold;
}

.txt26-black {
  @extend .h-4;
}

.symbol-required {
  color: $red-1;
}

.action-container {
  background: #f2f2f2;
}

.action-container:hover {
  background: #2B7DEC;
}

.action-container:hover > .action-icon {
  color: #f2f2f2;
}

.action-icon {
  font-size: 16px;
  color: #2B7DEC;
}

tbody > tr:hover {
  background-color: transparent !important;
}
</style>
